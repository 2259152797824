import '@polymer/polymer/polymer-legacy.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-progress/paper-progress.js';
import '../katapult-elements/katapult-button.js';
import { SquashNulls } from '../../modules/SquashNulls.js';
import { CamelCase } from '../../modules/CamelCase.js';
import { GetJobData } from '../../modules/GetJobData.js';
import '@polymer/paper-toast/paper-toast.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { Papa } from 'papaparse';
import { PickAnAttribute } from '../../modules/PickAnAttribute.js';
import { NotifyResizable } from '../../modules/NotifyResizable.js';
import { Path } from '../../modules/Path.js';
import { GeofireTools } from '../../modules/GeofireTools.js';

var alertTypes = {
  error: {
    name: 'error',
    color: '#CA5A5A'
  },
  warning: {
    name: 'warning',
    color: '#C39032'
  },
  alert: {
    name: 'alert',
    color: '#CA5A5A'
  },
  message: {
    name: 'message',
    color: '#156090'
  }
};
Polymer({
  _template: html`
    <style>
      .createButton,
      .cancelButton {
        width: 170px;
        margin-top: 20px;
      }
      .createButton[disabled] {
        background-color: #adddc6;
      }
      paper-progress {
        width: 100%;
      }
      #logs {
        padding: 7px;
        overflow: auto;
        max-height: 175px;
      }
      #fixHeadersWrapper {
        position: relative;
        padding: 5px;
        margin-top: 10px;
      }
      #fixHeadersList {
        overflow: auto;
        max-height: 200px;
      }
      #container {
        max-width: 900px;
        min-width: 500px;
        text-align: center;
      }
      #loading {
        border-radius: 10px;
      }
      #importOptions {
        display: flex;
        flex-direction: column;
        margin: 25px 0px;
        align-items: flex-start;
      }
      .option {
        padding: 4px 0px;
      }
      .logItem {
        padding: 4px 0px;
      }
      .optionDropdownRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .checkbox {
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        flex-direction: row;
        display: flex;
      }
    </style>

    <paper-toast id="toast"></paper-toast>
    <paper-dialog id="loading" transition="core-transition-center" no-cancel-on-outside-click="true">
      <paper-dialog-scrollable>
        <div id="container">
          <div>{{titleText}}</div>
          <paper-progress value="{{progressPercent}}"></paper-progress>
          <template is="dom-if" if="[[!jobId]]">
            <h2>Job Creation Options (required)</h2>
            <div class="optionDropdownRow">
              <span style="padding-right: 1.2em"> Model: </span>
              <katapult-drop-down
                items="[[modelOptions]]"
                label-path="label"
                value-path="value"
                value="{{newJobModel}}"
              ></katapult-drop-down>
            </div>
            <template is="dom-if" if="[[newJobModel]]">
              <div class="optionDropdownRow">
                <span style="padding-right: 1.2em"> Map Styles: </span>
                <katapult-drop-down
                  items="{{companyMapStyles}}"
                  value="{{newJobMapStyles}}"
                  value-path="value"
                  label-path="label"
                  no-label-float=""
                ></katapult-drop-down>
              </div>
              <p><em> Note: If a map styles model is not selected, the default model will be used </em></p>
            </template>
          </template>
          <template is="dom-if" if="{{hasSubJobs}}">
            <div id="importOptions">
              <!-- If there is no job id, we want to tell the user to select a model and map styles -->
              <template is="dom-if" if="{{importingConns}}">
                <paper-checkbox
                  class="option"
                  checked="{{addConnsToMasterJob}}"
                  disabled$="{{checkTotalItemCount(totalItemCount)}}"
                  on-change="reReadFiles"
                  >Add to Master Job (add all connections to current job)</paper-checkbox
                >
              </template>
              <template is="dom-if" if="{{!importingConns}}">
                <template is="dom-if" if="[[jobId]]">
                  <paper-checkbox class="option" checked="{{createMasterJob}}" disabled$="{{checkTotalItemCount(totalItemCount)}}"
                    >Create Master Job (add all nodes to current job)</paper-checkbox
                  >
                </template>
                <paper-checkbox class="option" checked="{{breakOutSubJobs}}" disabled$="{{!jobId}}"
                  >Create sub-jobs based on job_name column</paper-checkbox
                >
              </template>
            </div>
          </template>
          <template is="dom-if" if="{{!hasSubJobs}}">
            <template is="dom-if" if="{{!updateAttr}}">
              <template is="dom-if" if="{{!importingConns}}">
                <br />
                <br />
                <span class="muted">No sub-jobs to create.</span>
              </template>
            </template>
          </template>
          <div id="logs">
            <template is="dom-repeat" items="{{reversedAlertKeys}}" as="alertKey">
              <div class="logItem" style$="{{getAlertStyle(alertKey, alerts.*)}}"><span>{{getAlertMessage(alertKey, alerts.*)}}</span></div>
            </template>
          </div>
          <div id="fixHeadersWrapper" katapult-drop-down-scroll-target>
            <template is="dom-if" if="{{invalidHeaders.length}}">
              <div style="display: flex; align-items: center; justify-content: center;">
                <h2>Fix Headers</h2>
                <katapult-button
                  icon$="{{getFixHeaderIcon(fixHeaderCollapseOpened)}}"
                  iconOnly
                  noBorder
                  on-click="toggleFixHeadersCollapse"
                ></katapult-button>
              </div>
              <iron-collapse id="fixHeadersCollapse" opened="[[fixHeaderCollapseOpened]]" on-transitioning-changed="resizeDialog">
                <div id="fixHeadersList">
                  <template is="dom-repeat" items="{{invalidHeaders}}" as="header">
                    <div style="display: flex; align-items: center">
                      <div style="display: block; width: 40%">{{header.name}}</div>
                      <katapult-drop-down
                        style="display: block; margin: 10px; width: 60%"
                        value="{{header.headerValue}}"
                        label-path="name"
                        items="[[attributesList]]"
                        on-selected-changed="updateInvalidHeader"
                      ></katapult-drop-down>
                    </div>
                  </template>
                </div>
              </iron-collapse>
            </template>
          </div>
          <div id="uniqueAttr">
            <template is="dom-if" if="[[updateAttr]]">
              <h2>Select a unique attribute to identify each node</h2>
              <div style="display: flex; align-items: center">
                <div style="display: block; width: 40%">Unique Identifying Attribute Column</div>
                <katapult-drop-down
                  id="updateAttrDropdown"
                  style="display: block; margin: 10px; width: 60%"
                  value="{{updateAttrSelected}}"
                  label-path="name"
                  value-path="value"
                  items="[[importedHeaders]]"
                  on-selected-changed="updateAttrProcessing"
                ></katapult-drop-down>
              </div>
            </template>
          </div>
          <paper-checkbox class="checkbox" checked="{{useAddress}}" on-checked-changed="useAddressChanged"
            >Use address instead of coordinates</paper-checkbox
          >
          <katapult-button class="cancelButton" on-click="clickedCancel">Cancel</katapult-button>
          <katapult-button class="createButton" color="var(--primary-color)" on-click="createJob" disabled$="{{!valid}}"
            >{{buttonLabel}}</katapult-button
          >
        </div>
      </paper-dialog-scrollable>
    </paper-dialog>
  `,

  is: 'job-uploader',

  properties: {
    jobId: {
      notify: true,
      observer: 'jobIdChanged'
    },
    jobNodes: {
      notify: true
    },
    jobConns: {
      notify: true
    },
    userGroup: {
      notify: false
    },
    buttonLabel: {
      value: 'Add to Job'
    },
    disabled: Boolean,
    correctedHeaders: {
      value: []
    },
    readyToImport: {
      type: Boolean,
      value: true
    },
    fixHeaderCollapseOpened: {
      type: Boolean,
      value: true
    },
    importJobsLookup: {
      type: Object,
      value: {}
    },
    requiredFields: {
      type: Array,
      value: () => {
        return ['latitude', 'longitude'];
      }
    }
  },

  observers: ['setAttributesList(attributeData)', 'jobModelChanged(newJobModel)'],

  created: () => {},

  resetData: function () {
    this.subJobList = {};
    this.subJobsToUpdate = {};
    this.correctedHeaders = [];
    this.importedHeaders = [];
    this.hasSubJobs = false;
    this.breakOutSubJobs = true;
    this.alerts = [];
    this.reversedAlertKeys = [];
    this.progressPercent = 0;
    this.valid = false;
    this.dataObject = null;
    this.set('invalidHeaders', []);
    this.titleText = 'Checking your data - hang tight!';
    this.updateAttr = false;
    this.updateAttrSelected = false;
    this.attrUpdate = {};
    this.fixHeaderCollapseOpened = true;
    this.importJobsLookup = {};
    if (this.jobId) {
      this.buttonLabel = 'Add to Job';
    } else {
      this.buttonLabel = 'Create Job';
    }
  },

  reReadFiles: function (e) {
    this.readFiles(this.files);
  },

  jobIdChanged: function () {
    this.$.loading.close();
    this.resetData();
  },

  getStyleList: function (companyStyles) {
    var list = [];
    for (var id in companyStyles) {
      list.push({
        value: id,
        label: companyStyles[id]._name || id,
        icons: [{ icon: 'create', title: 'Edit', clickable: true }]
      });
      if (companyStyles[id]._default) {
        list[list.length - 1].icons.unshift({ icon: 'done', title: 'Default' });
      }
    }
    return list;
  },

  jobModelChanged: async function () {
    this.resetData();
    if (this.newJobModel) {
      // get the attributes and map styles from the model
      let companyAttributes = await FirebaseWorker.ref(`photoheight/company_space/${this.newJobModel}/models/attributes`)
        .once('value')
        .then((s) => s.val());
      // add all the map styles available to the company (included under the user group)
      let companyMapStylesObject = await FirebaseWorker.ref(`photoheight/company_space/${this.userGroup}/models/map_styles`)
        .once('value')
        .then((s) => s.val());
      let companyMapStyles = this.getStyleList(companyMapStylesObject);
      // set polymer properties
      this.set('attributesData', companyAttributes);
      this.set('companyMapStyles', companyMapStyles);
    } else this.set('attributesData', null);
    if (this.files) this.readFiles(this.files);
  },

  validate: function () {
    this.valid = !this.alertsContainErrors() && this.dataObject && Object.keys(this.dataObject).length > 0;

    // we want to disable this feature (make it invalid) if there is not a jobId and there are no subJobs.
    if (this.valid && !this.jobId && !this.hasSubJobs) {
      this.createAlert(alertTypes.alert, 'You need to select a job with the job chooser to import nodes');
      this.valid = false;
    }
    return this.valid;
  },

  alertsContainErrors: function () {
    for (var i = 0; i < this.alerts.length; i++) {
      if (this.alerts[i].type.name == 'error') {
        return true;
      }
    }
    return false;
  },

  alertsContainWarnings: function () {
    for (var i = 0; i < this.alerts.length; i++) {
      if (this.alerts[i].type.name == 'warning') {
        return true;
      }
    }
    return false;
  },

  createAlert: function (type, message) {
    this.push('alerts', {
      type: type,
      message: message
    });
    // Add the last index of the alerts array to the beginning of the reversedAlertKeys array
    this.unshift('reversedAlertKeys', this.alerts.length - 1);
  },

  updateInvalidHeader: function (e) {
    let headerName = e.model.__data.header.name;

    if (e && e.detail && e.detail.selectedItem && e.detail.selectedItem.attr) {
      // remove header from invalidHeaders
      for (var i = 0; i < this.invalidHeaders.length; i++) {
        if (this.invalidHeaders[i].name == headerName) {
          this.invalidHeaders.splice(i, 1);
          break;
        }
      }
      // add to correctedHeaders
      this.correctedHeaders.push({ header: headerName, value: e.detail.selectedItem.attr });
      if (this.invalidHeaders.length == 0) {
        this.readFiles(this.files);
      }
      // update the imported headers list used to select the unique attribute
      if (this.importedHeaders) {
        let updatedImportedHeaders = this.importedHeaders.map((item) => {
          if (item.name == headerName) {
            return { name: item.name, value: e.detail.selectedItem.attr };
          }
          return item;
        });
        this.updateAttrSelected = null;
        this.importedHeaders = updatedImportedHeaders;
      }
    } else {
      for (var i = 0; i < this.correctedHeaders.length; i++) {
        if (this.correctedHeaders[i].header == headerName) {
          // remove from correctedHeaders list
          this.correctedHeaders.splice(i, 1);
          break;
        }
      }
      // add header back to invalidHeaders
      this.invalidHeaders.push({ name: headerName });
      // all fields are filled in and there aren't errors
      if (!this.alertsContainErrors()) this.valid = false;
      this.readFiles(this.files);
    }
  },

  readFiles: function (files, buttonId, options = {}) {
    if (!files) return;

    this.files = structuredClone(files);
    options = options || {};
    options.importAddresses = options.importAddresses ?? this.useAddress;
    if (buttonId) {
      if (buttonId == 'attrDropZone') {
        this.importingConns = false;
        this.updateAttr = true;
      } else if (buttonId == 'nodeDropZone') {
        if (!this.jobId) {
        }
        this.importingConns = false;
        this.updateAttr = false;
        this.set('requiredFields', ['latitude', 'longitude']);
      } else if (buttonId == 'connDropZone') {
        this.importingConns = true;
        this.updateAttr = false;
        this.set('requiredFields', ['latitude1', 'longitude1', 'latitude2', 'longitude2']);
      }
    }
    if (options?.importAddresses) {
      this.set('requiredFields', ['address']);
    }

    var hasCorrectFile = false;
    var file = null;
    var extension = '';
    for (var i = 0; i < files.length; i++) {
      var fileParts = files[i].name.split('.');
      extension = fileParts[fileParts.length - 1];
      if (extension == 'xls' || extension == 'xlsx' || extension == 'csv') {
        hasCorrectFile = true;
        file = files[i];
        break;
      }
    }
    if (hasCorrectFile) {
      this.$.loading.open();
      /* set up XMLHttpRequest */
      var fileReader = new FileReader();
      fileReader.onload = function (e) {
        var arraybuffer = fileReader.result;
        /* convert data to binary string */
        var data = new Uint8Array(arraybuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join('');
        var rowData = null;
        var headers = null;
        let promises = [];

        promises.push(
          new Promise(async (resolve, reject) => {
            if (extension == 'csv') {
              bstr = bstr.replace('ï»¿', '');
              Papa.parse(bstr.trim(), {
                delimiter: ',',
                header: false,
                skipEmptyLines: 'greedy',
                dynamicTyping: false,
                worker: true,
                complete: async function (results, parser) {
                  let papaData = results;
                  if (papaData.errors.length > 0) {
                    alert('There was an error parsing the CSV');
                  } else {
                    // Get the headers and row data from papa parse and convert
                    // row data to object format to match XLSX format
                    rowData = [];
                    headers = papaData.data.shift();
                    papaData.data.forEach((row) => {
                      let tempRowData = {};
                      headers.forEach((headerKey, colIndex) => {
                        let rowValue = row[colIndex];
                        // Use a counter to determine a new unique header key to use
                        let headerKeyCount = 0;
                        let newHeaderKey = '';
                        // Check if the new header key is already in tempRowData
                        do {
                          // Compute the new header key by putting the index after a _ (if not 0)
                          newHeaderKey = `${headerKey}${headerKeyCount ? '_' + headerKeyCount : ''}`;
                          headerKeyCount++;
                        } while (tempRowData.hasOwnProperty(newHeaderKey));
                        // Add the row value to the tempRowData with the new header key
                        tempRowData[newHeaderKey] = rowValue;
                      });
                      rowData.push(tempRowData);
                    });
                  }
                  resolve();
                }.bind(this)
              });
            } else {
              const { read: xlsxRead, utils: xlsxUtils } = await import('xlsx');

              var workbook = xlsxRead(bstr, {
                type: 'binary'
              });
              // Get the first worksheet
              var firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
              // Separate data with '\,' to help parsing values that contain commas
              let sheetData = xlsxUtils.sheet_to_csv(firstWorksheet, {
                FS: '\\,'
              });
              if (sheetData) {
                let splitRegex = /[\\],/g;
                let lineSplitRegex = /\n(?!$)/g;
                // Get the lines of the CSV and the header row
                let lines = sheetData.split(lineSplitRegex);
                // Split at escaped commas
                headers = lines[0].split(splitRegex);
                rowData = xlsxUtils.sheet_to_json(firstWorksheet, {
                  FS: '\\,'
                });
              }
              resolve();
            }
          })
        );

        // wrap in a promise to accommodate for large files being loaded
        Promise.all(promises).then(async () => {
          if (this.correctedHeaders.length > 0) {
            this.correctedHeaders.forEach((header) => {
              for (var t = 0; t < rowData.length; t++) {
                if (rowData[t][header.header]) {
                  rowData[t][header.value] = rowData[t][header.header];
                  delete rowData[t][header.header];
                }
              }
            });
          }
          this.dataObject = await this.createObjectFromData(rowData, headers, options);
          // add attributes to existing nodes code
          if (this.updateAttr) {
            this.updateAttrProcessing();
          } else if (this.importingConns) {
            if (this.subJobsToUpdate && Object.keys(this.subJobsToUpdate).length > 0) {
              this.hasSubJobs = true;
              for (let subJobName in this.subJobsToUpdate) {
                this.createAlert(
                  alertTypes.message,
                  `Job "${subJobName}" will have ${this.subJobsToUpdate[subJobName].length} connections(s) added to it.`
                );
              }
            } else {
              this.hasSubJobs = false;
            }
          } else {
            if (this.subJobList && Object.keys(this.subJobList).length > 0) {
              this.hasSubJobs = true;
              for (let subJobName in this.subJobList) {
                let jobData = this.subJobList[subJobName][0];
                //   check first item in list to see if it's a folder structure
                if (PickAnAttribute(jobData.attributes, 'job_path')) {
                  let path = this.userGroup + '/' + PickAnAttribute(jobData.attributes, 'job_path');
                  this.createAlert(
                    alertTypes.message,
                    `Job "${subJobName}" will be created and placed in Folder: ${path} and ${this.subJobList[subJobName].length} node(s) will be added to it.`
                  );
                } else
                  this.createAlert(
                    alertTypes.message,
                    `Job "${subJobName}" will be created and ${this.subJobList[subJobName].length} node(s) will be added to it.`
                  );
              }
              //   if there were duplicate job names, generate warning
              if (Object.keys(this.importJobsLookup).some((x) => this.importJobsLookup[x].duplicate))
                this.createAlert(alertTypes.error, 'Duplicate jobs were found. Please make sure you have unique job names.');
            } else {
              this.hasSubJobs = false;
            }

            // If no job is selected, then warn the user that the attributes won't be checked
            if (!this.jobId && !this.newJobModel) {
              this.createAlert(
                alertTypes.warning,
                `Warning: Since you are creating a new job and a model hasn't been selected, the model to use cannot be determined and your import will not be checked for valid attributes`
              );
            }
          }
          this.validate();

          var itemCount = Object.keys(this.dataObject).length;
          // If there were no errors, show a total
          if (!this.alertsContainErrors() && !this.invalidHeaders.length) {
            let itemName = this.importingConns ? 'connection' : 'node';
            if (itemCount == 0) {
              this.titleText = `Sorry, there are no ${itemName}s to import...`;
              this.createAlert(alertTypes.message, `${itemCount} ${itemName}s to import`);
            } else {
              this.titleText = 'Your data is ready to import!';
              var warningNotice = this.alertsContainWarnings() ? ', but check warnings first!' : '';
              if (!this.updateAttr) {
                this.totalItemCount = itemCount;
                if (itemCount == 1) {
                  this.createAlert(alertTypes.message, `${itemCount} ${itemName} is ready to import ${warningNotice}`);
                } else {
                  this.createAlert(alertTypes.message, `${itemCount} ${itemName}s are ready to import ${warningNotice}`);
                  if (Object.keys(this.subJobList).length > 0) {
                    let jobCount = Object.keys(this.subJobList).length;
                    this.createAlert(alertTypes.message, `${jobCount} ${jobCount != 1 ? 'jobs' : 'job'} will be created` + warningNotice);
                    this.buttonLabel = 'Create Jobs';
                  }
                }
              }
            }
          } else {
            this.titleText = 'There were some issues. Please fix them before importing!';
          }
          this.$.loading.notifyResize();
        });
      }.bind(this);
      // Start the file reader
      fileReader.readAsArrayBuffer(file);
    } else {
      alert('Please upload a .xls .xlsx, or .csv file.');
    }
  },

  updateAttrProcessing: async function (e) {
    if (!this.updateAttrSelected) {
      this.createAlert(
        alertTypes.error,
        `In order to update the node attributes properly, please select a column that has unique data that will be used to identify each node.`
      );
    }
    if (Object.keys(this.dataObject).length != 0 && this.updateAttrSelected) {
      // Build an array of nodes from the dataObject list
      var importedNodes = [];
      for (var key in this.dataObject) {
        importedNodes.push(this.dataObject[key]);
      }
      let maps = document.body.querySelector('katapult-maps-desktop') ?? document.body.querySelector('katapult-maps-mobile');
      let update = {};
      let nodes = await FirebaseWorker.ref(`photoheight/jobs/${this.jobId}/nodes`)
        .once('value')
        .then((s) => s.val());
      let matchedNodes = 0;
      let unmatchedNodes = [];
      let uniqueAttributes = [];
      // catch any attribute formatting issues
      let formattedAttributes = {};
      for (let key in this.attributesData) {
        formattedAttributes[key.toLowerCase()] = key;
      }
      let formattedUniqueAttr = formattedAttributes[this.updateAttrSelected.toLowerCase().trim().replace(/ /g, '_')];

      for (let i = 0; i < importedNodes.length; i++) {
        let foundMatch = false;
        let matchCount = 0;
        if (this.updateAttrSelected == 'latitude and longitude') {
          if (importedNodes[i].latitude && importedNodes[i].longitude) {
            uniqueAttributes.push(`${importedNodes[i].latitude}, ${importedNodes[i].longitude}`);
            Object.keys(nodes || {}).forEach((key) => {
              if (
                Math.abs(Number(nodes[key].latitude).toFixed(6) - Number(importedNodes[i].latitude).toFixed(6)) < 0.00001 &&
                Math.abs(Number(nodes[key].longitude).toFixed(6) - Number(importedNodes[i].longitude).toFixed(6)) < 0.00001
              ) {
                matchedNodes++;
                for (let prop in importedNodes[i].attributes) {
                  if (prop != 'node_id') {
                    update[`nodes/${key}/attributes/${prop}`] = importedNodes[i].attributes[prop];
                    Path.set(nodes[key], `attributes.${prop}`, importedNodes[i].attributes[prop]);
                  }
                }
                GeofireTools.updateStyle('nodes', key, nodes[key], update, maps.jobStyles);
                foundMatch = true;
                matchCount++;
              }
            });
          }
        } else if (this.updateAttrSelected == 'node_id') {
          if (importedNodes[i].attributes.node_id) {
            uniqueAttributes.push(`${PickAnAttribute(importedNodes[i].attributes, 'node_id')}`);
            Object.keys(nodes || {}).forEach((key) => {
              if (key == PickAnAttribute(importedNodes[i].attributes, 'node_id')) {
                matchedNodes++;
                // check if there is latitude and longitude to be updated
                if (importedNodes[i].latitude || importedNodes[i].longitude) {
                  update[`nodes/${key}/latitude`] = importedNodes[i].latitude || nodes[key].latitude;
                  update[`nodes/${key}/longitude`] = importedNodes[i].longitude || nodes[key].longitude;
                  GeofireTools.updateLocation(
                    'geohash/' + key,
                    [importedNodes[i].latitude || nodes[key].latitude, importedNodes[i].longitude || nodes[key].longitude],
                    10,
                    update
                  );
                }
                // update the rest of the attributes
                for (let prop in importedNodes[i].attributes) {
                  if (prop != 'node_id') {
                    update[`nodes/${key}/attributes/${prop}`] = importedNodes[i].attributes[prop];
                    Path.set(nodes[key], `attributes.${prop}`, importedNodes[i].attributes[prop]);
                  }
                }
                GeofireTools.updateStyle('nodes', key, nodes[key], update, maps.jobStyles);
                foundMatch = true;
                matchCount++;
              }
            });
          }
        } else {
          if (PickAnAttribute(importedNodes[i].attributes, formattedUniqueAttr)) {
            uniqueAttributes.push(PickAnAttribute(importedNodes[i].attributes, formattedUniqueAttr));
            Object.keys(nodes || {}).forEach((key) => {
              if (
                PickAnAttribute(nodes[key].attributes, formattedUniqueAttr) ==
                PickAnAttribute(importedNodes[i].attributes, formattedUniqueAttr)
              ) {
                matchedNodes++;
                // check if there is latitude and longitude to be updated
                if (importedNodes[i].latitude || importedNodes[i].longitude) {
                  update[`nodes/${key}/latitude`] = importedNodes[i].latitude || nodes[key].latitude;
                  update[`nodes/${key}/longitude`] = importedNodes[i].longitude || nodes[key].longitude;
                  GeofireTools.updateLocation(
                    'geohash/' + key,
                    [importedNodes[i].latitude || nodes[key].latitude, importedNodes[i].longitude || nodes[key].longitude],
                    10,
                    update
                  );
                }
                // update the rest of the attributes
                for (let prop in importedNodes[i].attributes) {
                  if (prop != formattedUniqueAttr && prop != 'node_id') {
                    update[`nodes/${key}/attributes/${prop}`] = importedNodes[i].attributes[prop];
                    Path.set(nodes[key], `attributes.${prop}`, importedNodes[i].attributes[prop]);
                  }
                }
                GeofireTools.updateStyle('nodes', key, nodes[key], update, maps.jobStyles);
                foundMatch = true;
                matchCount++;
              }
            });
          }
        }
        if (matchCount > 1) {
          if (this.updateAttrSelected == 'latitude and longitude') {
            this.createAlert(
              alertTypes.warning,
              `Warning: The imported row with column name: 'latitude' and cell value: ${SquashNulls(
                importedNodes[i],
                'latitude'
              )} is matched to more than one node in this job`
            );
          } else {
            let columnName = formattedUniqueAttr;
            // look through the list of importedHeaders and use the original column name
            // to avoid confusion for the user
            for (let i = 0; i < this.importedHeaders.length; i++) {
              let header = this.importedHeaders[i];
              if (header.value.toLowerCase() == formattedUniqueAttr.toLowerCase()) {
                columnName = header.name;
                break;
              }
            }
            this.createAlert(
              alertTypes.warning,
              `Warning: The imported row with column name: '${columnName}' and cell value: ${PickAnAttribute(
                importedNodes[i].attributes,
                formattedUniqueAttr
              )} is matched to more than one node in this job`
            );
          }
        }
        if (!foundMatch) {
          let unmatchedNode = {};
          // get the unique identifying attribute to identify the row
          if (this.updateAttrSelected == 'latitude and longitude') {
            unmatchedNode['header'] = 'latitude';
            unmatchedNode['value'] = SquashNulls(importedNodes[i], 'latitude');
          } else {
            unmatchedNode['header'] = this.updateAttrSelected;
            unmatchedNode['value'] = PickAnAttribute(importedNodes[i].attributes, formattedUniqueAttr);
          }
          unmatchedNodes.push({ header: unmatchedNode.header, value: unmatchedNode.value });
        }
      }
      if (uniqueAttributes.length > 0) {
        uniqueAttributes.forEach((uniqueAttribute, i) => {
          if (uniqueAttributes.indexOf(uniqueAttribute) != i) {
            this.createAlert(
              alertTypes.warning,
              `Warning: A duplicate unique identifying attribute exists: ${uniqueAttribute ? uniqueAttribute : '*blank*'}`
            );
          }
        });
      }
      if (unmatchedNodes.length > 0) {
        unmatchedNodes.forEach((unmatchedNode) => {
          let columnName = unmatchedNode.header;
          // look through the list of importedHeaders and use the original column name
          // to avoid confusion for the user
          for (let i = 0; i < this.importedHeaders.length; i++) {
            let header = this.importedHeaders[i];
            if (header.value.toLowerCase() == unmatchedNode.header) {
              columnName = header.name;
              break;
            }
          }
          this.createAlert(
            alertTypes.warning,
            `Warning: The imported row with column name: '${columnName}' and cell value: ${
              unmatchedNode.value ? unmatchedNode.value : '*blank*'
            } does not match any nodes in this job`
          );
        });
      }
      if (matchedNodes > 0) {
        this.attrUpdate = update;
        if (matchedNodes > 1) {
          this.createAlert(alertTypes.message, `${matchedNodes} nodes are matched and ready to be updated`);
        } else {
          this.createAlert(alertTypes.message, `${matchedNodes} node is matched and ready to be updated`);
        }
      } else {
        this.createAlert(alertTypes.warning, `Warning: No matches were found in the job.`);
      }
    } else {
      this.progressPercent = 0;
    }
    // re-run readFiles if this function was called by selecting the unique identifying attribute
    if (e) {
      this.readFiles(this.files);
    }
  },

  jobExistsWithName: async function (jobName) {
    let jobId = await this.getJobIdByName(jobName);
    return jobId != null;
  },

  getJobIdByName: async function (jobName) {
    return await firebase
      .database()
      .ref(`photoheight/job_permissions/${this.userGroup}/list`)
      .orderByChild('name')
      .equalTo(jobName)
      .once('value')
      .then((s) => Object.keys(s.val() || {})[0]);
  },

  getJobNodesByName: async function (jobName) {
    if (jobName) {
      let jobId = await this.getJobIdByName(jobName);
      if (jobId) {
        return FirebaseWorker.ref(`photoheight/jobs/${jobId}/nodes`)
          .once('value')
          .then((s) => s.val());
      }
    }
    return null;
  },

  getJobConnectionsByName: async function (jobName) {
    if (jobName) {
      let jobId = await this.getJobIdByName(jobName);
      if (jobId) {
        return FirebaseWorker.ref(`photoheight/jobs/${jobId}/connections`)
          .once('value')
          .then((s) => s.val());
      }
    }
    return null;
  },

  createJobEntry: function (jobName, model, mapStyles, jobProjectFolderPath, callback) {
    // Create an options object for creating the job
    let jobOptions = {
      name: jobName,
      mapStyles,
      model,
      jobProjectFolderPath,
      preventNewJobSelection: true
    };
    // Create the job using the job chooser element
    this.createJobForm.createNewJob(jobOptions, (res) => {
      if (res.status != 'success') {
        this.createAlert(alertTypes.error, `Error: ${res.message}`);
      }
      let jobRef = null;
      if (res.job_id) {
        jobRef = FirebaseWorker.ref('photoheight/jobs').child(res.job_id);
      }
      callback(jobRef);
    });
  },

  createJob: async function () {
    // if it doesn't have sub jobs, run the code to check to see if a job id exists because we're creating a new job
    // if it does have sub jobs, we don't need a job id, because we can use the inputted model and map styles
    if (!this.hasSubJobs) {
      var jobIdExists = this.jobId != null && this.jobId != '';
      // Check if a job is selected
      if (jobIdExists == false) {
        this.createAlert(alertTypes.alert, 'Please select a job.');
        return;
      }
    }
    // Check if the data is valid
    if (this.validate()) {
      // Get a reference to the job you're adding to
      let mainJobRef = {};
      if (this.jobId) mainJobRef = FirebaseWorker.ref('photoheight/jobs').child(this.jobId);

      // if we are only updating the attributes, do that
      if (this.updateAttr) {
        // you won't be able to only update attributes if a job id not selected so this is fine (mainJobRef will always exist here)
        FirebaseWorker.ref('photoheight/jobs/' + this.jobId).update(this.attrUpdate);
        this.finishJobUpdate(mainJobRef.key);
      } else {
        // Get data from the job
        let jobData;
        if (this.jobId) {
          jobData = await GetJobData(this.jobId, ['map_styles', 'project_folder', 'job_creator']);
          if (jobData.map_styles) jobData.map_styles = Object.values(jobData.map_styles)[0];
          // if the job doesn't have map_styles then give them the option to choose the map styles
          else {
            // toast and open the map styles editor
            // get the katpult maps desktop element
            let maps = document.body.querySelector('katapult-maps-desktop');
            if (maps?.editMapStyles) maps.editMapStyles();
            else this.createAlert(alertTypes.alert, 'Could not open map styles editor.  Configure maps styles, then try the import again');

            this.toast('Please select a map styles model, then try the import again');
            return;
          }
        } else {
          if (!this.newJobModel) {
            this.createAlert(alertTypes.alert, 'Please select a job model for the new sub jobs');
            return;
          }

          // get the styles from the database
          let mapStylesModel = 'default';
          if (this.newJobMapStyles) mapStylesModel = this.newJobMapStyles;
          let mapStyles = await FirebaseWorker.ref(`photoheight/company_space/${this.newJobModel}/models/map_styles/${mapStylesModel}`)
            .once('value')
            .then((s) => s.val());

          // we will only get to this code if we're adding sub jobs without having a job selected (bulk job adding)
          jobData = {
            job_creator: this.newJobModel,
            map_styles: mapStyles
            // project_folder: don't need a project folder cause this will be reset in the creation of sub jobs below
          };
        }

        let jobStyles = jobData?.map_styles ? { default: jobData.map_styles } : null;

        // Create counters
        this.progressPercent = 0;
        this.itemsProcessed = 0;
        let projectFolder = jobData.project_folder;
        if (this.importingConns) {
          // Check if we have a subjob list
          if (this.subJobsToUpdate && Object.keys(this.subJobsToUpdate).length > 0) {
            let subJobRemainingCount = Object.keys(this.subJobsToUpdate).length;
            for (let jobName in this.subJobsToUpdate) {
              // get the conns to add for the current job
              let connList = this.subJobsToUpdate[jobName];
              this.getJobIdByName(jobName).then((subJobId) => {
                if (subJobId) {
                  let subJobRef = FirebaseWorker.ref('photoheight/jobs').child(subJobId);
                  this.addItemsToJobRef(connList, subJobRef, jobStyles, false, () => {
                    // Check if we are done all the sub jobs
                    subJobRemainingCount--;
                    if (subJobRemainingCount == 0) {
                      if (this.addConnsToMasterJob) this.addToMasterJob(mainJobRef);
                      else this.finishJobUpdate(mainJobRef.key);
                    }
                  });
                }
              });
            }
          } else {
            this.addToMasterJob(mainJobRef);
          }
        } else {
          // Check if we have a subjob list
          if (this.subJobList && Object.keys(this.subJobList).length > 0) {
            // Check if we should break out the sub jobs
            if (this.breakOutSubJobs) {
              let allNodes = [];
              let subJobRemainingCount = Object.keys(this.subJobList).length;
              // Loop through the sub jobs
              Object.keys(this.subJobList).forEach((jobNameKey) => {
                setTimeout(() => {
                  this.titleText = 'Creating sub-job "' + jobNameKey + '"...';
                  // Get the next sub job's reference

                  if (PickAnAttribute(this.subJobList[jobNameKey][0].attributes, 'job_path')) {
                    let path = PickAnAttribute(this.subJobList[jobNameKey][0].attributes, 'job_path');
                    let extraFolderPath = path.split('/');
                    // remove job name from folder path
                    extraFolderPath.pop();
                    extraFolderPath = extraFolderPath.join('/');

                    // rebuild the folder path
                    if (extraFolderPath) {
                      projectFolder = this.userGroup + '/' + extraFolderPath;
                    } else projectFolder = this.userGroup;
                  }
                  this.createJobEntry(jobNameKey, jobData.job_creator, jobData.map_styles, projectFolder, (nextJobRef) => {
                    if (nextJobRef) {
                      // Get the current node list
                      var nodeList = this.subJobList[jobNameKey];
                      this.addItemsToJobRef(nodeList, nextJobRef, jobStyles, false, () => {
                        for (let j = 0; j < nodeList.length; j++) {
                          allNodes.push(nodeList[j]);
                        }
                        // Check if we are done all the sub jobs
                        subJobRemainingCount--;
                        if (subJobRemainingCount == 0) {
                          if (this.createMasterJob) this.addToMasterJob(mainJobRef, allNodes);
                          else this.finishJobUpdate(mainJobRef.key);
                        }
                      });
                    }
                  });
                }, 1);
              });
            } else {
              this.addToMasterJob(mainJobRef);
            }
          } else {
            this.addToMasterJob(mainJobRef);
          }
        }
      }
    }
  },

  addToMasterJob: function (mainJobRef, items) {
    this.titleText = this.importingConns ? 'Uploading Connections...' : 'Uploading Nodes...';

    // Build an array of items from the dataObject list
    if (items) tempArray = items;
    else {
      var tempArray = [];
      for (var key in this.dataObject) {
        tempArray.push(this.dataObject[key]);
      }
    }

    // Add the items to the main job
    this.addItemsToJobRef(tempArray, mainJobRef, null, true, () => this.finishJobUpdate(mainJobRef.key));
  },

  finishJobUpdate: function (jobKey) {
    this.jobId = jobKey;
    // Update the job list
    this.$.loading.close();
    this.toast(`Done importing ${this.importingConns ? 'connections' : 'nodes'}`);
    this.resetData();
  },

  addItemsToJobRef: function (items, ref, jobStyles, isMasterJob, callback) {
    if (ref) {
      // In the case we're adding the nodes to a master job, fallback on the job styles from the map if none as passed in
      const maps = document.body.querySelector('katapult-maps-desktop');
      jobStyles ??= maps.jobStyles;

      // Create an update object for the new job data
      let update = {};

      // Loop through the items to add them to the update
      for (let i = 0; i < items.length; i++) {
        // Get the id to use for the item (if not already set, generate a new one)
        // We need to set the key on the item so we can reference it later for the master job (we remove it from non-master job items)
        const itemId = (items[i].key ??= ref.push().key);
        // Make a copy of the item so we can remove properties we don't need
        const copyOfItem = structuredClone(items[i]);

        // If not the master job, remove job-splitting attributes
        if (!isMasterJob) {
          delete copyOfItem.key;
          delete copyOfItem.attributes.job_name;
          delete copyOfItem.attributes.job_path;
          delete copyOfItem.attributes.app_type;
          delete copyOfItem.attributes.node_id;
          delete copyOfItem.attributes.conn_id;
        }

        if (this.importingConns) {
          const { latitude1, longitude1, latitude2, longitude2 } = copyOfItem;

          // clean out the unnecessary attributes from the conn data
          delete copyOfItem.latitude1;
          delete copyOfItem.longitude1;
          delete copyOfItem.latitude2;
          delete copyOfItem.longitude2;
          // delete job_name if it exists
          delete copyOfItem.attributes.job_name;

          // Add the conn data to the update
          update[`connections/${itemId}`] = copyOfItem;
          // Update the geo styles for the conn
          GeofireTools.setGeohash('connections', copyOfItem, itemId, jobStyles, update, {
            location1: [latitude1, longitude1],
            location2: [latitude2, longitude2],
            nId1: copyOfItem.node_id_1,
            nId2: copyOfItem.node_id_2
          });
        } else {
          // Add the node data to the update
          update[`nodes/${itemId}`] = copyOfItem;
          // Update the geo styles for the node
          GeofireTools.setGeohash('nodes', copyOfItem, itemId, jobStyles, update);
        }

        // Update the progress counts
        this.itemsProcessed++;
        this.progressPercent = Math.ceil((this.itemsProcessed / this.totalItemCount) * 100);
      }
      // Run the update
      ref.update(update, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  },

  getFixHeaderIcon() {
    if (this.fixHeaderCollapseOpened) return 'arrow_drop_up';
    else return 'arrow_drop_down';
  },

  toggleFixHeadersCollapse(e) {
    this.fixHeaderCollapseOpened = !this.fixHeaderCollapseOpened;
  },
  resizeDialog() {
    NotifyResizable('resizeDialog', this.shadowRoot.querySelector('#loading'), 1000);
  },

  clickedCancel: function () {
    this.$.loading.close();
    this.resetData();
  },

  valueForJobNode: function (key, property) {
    if (this.jobNodes && this.jobNodes[key]) {
      // If the property is latitude or longitude, check the formatted versions
      if (property == 'latitude' || property == 'longitude') {
        if (this.jobNodes[key][property]) return this.jobNodes[key][property];
      } else {
        if (this.jobNodes[key].attributes && this.jobNodes[key].attributes[property]) {
          return this.jobNodes[key].attributes[property];
        }
      }
    }
    return null;
  },

  jobNodesHaveValue: async function (property, value, options) {
    options = options || {};
    let count = 0;
    let jobNodes = this.jobNodes;
    if (options.jobName) {
      jobNodes = await this.getJobNodesByName(options.jobName);
    }
    if (jobNodes) {
      for (var key in jobNodes) {
        // Check the pair combined if we are given a pair of coordinates
        if (property == 'latlong') {
          var pair = value.split(',');
          pair[0] = pair[0].trim();
          pair[1] = pair[1].trim();
          if (
            this.formatCoordinate(jobNodes[key]['latitude']) == this.formatCoordinate(pair[0]) &&
            this.formatCoordinate(jobNodes[key]['longitude']) == this.formatCoordinate(pair[1])
          ) {
            if (options.returnCount) {
              count++;
            } else {
              return key;
            }
          }
        }
        // If the property is latitude or longitude, check the formatted versions
        if (property == 'latitude' || property == 'longitude') {
          if (this.formatCoordinate(jobNodes[key][property]) == this.formatCoordinate(value)) {
            if (options.returnCount) {
              count++;
            } else {
              return key;
            }
          }
        } else {
          if (jobNodes[key].attributes && jobNodes[key].attributes[property]) {
            var tempVal = jobNodes[key].attributes[property];
            var firstKey = Object.keys(tempVal)[0];
            if (tempVal[firstKey] == value) {
              if (options.returnCount) {
                count++;
              } else {
                return key;
              }
            }
          }
        }
      }
    }
    if (options.returnCount) {
      return count;
    }
    return false;
  },

  jobConnectionsHaveValue: async function (property, value, options) {
    options = options || {};
    let count = 0;
    let jobConns = this.jobConns;
    if (options.jobName) {
      jobConns = await this.getJobConnectionsByName(options.jobName);
    }
    if (jobConns) {
      // If we are given a pair of latlongs then split them up and grab the nodeId's they belong too
      let nodeId1 = '';
      let nodeId2 = '';
      if (property == 'latlong') {
        let latlngPair = value.split(':');
        latlngPair[0] = latlngPair[0].trim();
        latlngPair[1] = latlngPair[1].trim();

        // get a node key from each of the latlng pairs
        nodeId1 = await this.jobNodesHaveValue('latlong', latlngPair[0], { jobName: options.jobName });
        nodeId2 = await this.jobNodesHaveValue('latlong', latlngPair[1], { jobName: options.jobName });
      }
      for (var key in jobConns) {
        // if we are checking by latlong then check to see if any connection has
        // the same 2 nodeIds that we found by the latlongs passed in
        if (property == 'latlong') {
          if (nodeId1 && nodeId2) {
            if (
              (jobConns[key]['node_id_1'] == nodeId1 && jobConns[key]['node_id_2'] == nodeId2) ||
              (jobConns[key]['node_id_1'] == nodeId2 && jobConns[key]['node_id_2'] == nodeId1)
            ) {
              if (options.returnCount) {
                count++;
              } else {
                return key;
              }
            }
          } else {
            // if we didn't find 2 nodeIds in the job from the coordinates then just end the loop because
            // there will be no connection found
            break;
          }
        } else {
          if (jobConns[key].attributes && jobConns[key].attributes[property]) {
            var tempVal = jobConns[key].attributes[property];
            var firstKey = Object.keys(tempVal)[0];
            if (tempVal[firstKey] == value) {
              if (options.returnCount) {
                count++;
              } else {
                return key;
              }
            }
          }
        }
      }
    }
    if (options.returnCount) {
      return count;
    }
    return false;
  },

  createObjectFromRow: async function (headerValues, rowValues, formattedAttributes, options) {
    // Create a new object for that row (to represent the pole for that row)
    var tempObject = {};
    if (this.importingConns) {
      tempObject = {
        attributes: {},
        latitude1: '',
        longitude1: '',
        latitude2: '',
        longitude2: ''
      };
    } else {
      tempObject = {
        attributes: {},
        latitude: '',
        longitude: ''
      };
    }
    // Keeps a record of header keys that have been used and how many times
    // so we can get the correct column values for the given key
    let usedHeaderKeys = {};
    // Loop through the header to check each corresponding value
    for (var i = 0; i < headerValues.length; i++) {
      let headerVal = headerValues[i];
      // Get and clean the key and value for this current item
      var key = headerVal.value ? headerVal.value.toLowerCase().trim().replace(/ /g, '_') : '';
      key = formattedAttributes[key] || key;
      // Get the count for how many times the current header key has been used
      // so far (because we will have incrementing header keys for duplicate attributes,
      // for example: note, note_1, note_2, etc). So we get the current count so
      // we can determine which key to use in the rowValues lookup
      let headerKeyCount = usedHeaderKeys[headerVal.value] || 0;
      // Compute the key to use for the rowValues lookup
      let rowValuesKey = `${headerVal.value}${headerKeyCount ? '_' + headerKeyCount : ''}`;
      // Update the count for the header key
      usedHeaderKeys[headerVal.value] = headerKeyCount + 1;
      var value = rowValues[rowValuesKey] ? String(rowValues[rowValuesKey]).trim() : '';
      if (key != '') {
        // Check if the attribute for the key is a checkbox gui element,
        // which needs to take a boolean value, and choose a boolean
        // value based on the user's input
        if (this.attributesData && this.attributesData[key] && this.attributesData[key].gui_element == 'checkbox') {
          value = this.textToBoolean(value);
        }
        if (this.attributesData && this.attributesData[key] && this.attributesData[key].gui_element == 'table') {
          value = this.textToTable(value, this.attributesData[key]);
        }
        // check if we should use address as latlong and if that header has been corrected
        const addressKey = headerVal.name == 'address' ? key : '';
        if (key == 'latitude_and_longitude' && !options.importAddresses) {
          if (this.importingConns) {
            tempObject['latitude1'] = rowValues['latitude1'] || rowValues['Latitude1'] || '';
            tempObject['longitude1'] = rowValues['longitude1'] || rowValues['Longitude1'] || '';
            tempObject['latitude2'] = rowValues['latitude2'] || rowValues['Latitude2'] || '';
            tempObject['longitude2'] = rowValues['longitude2'] || rowValues['Longitude2'] || '';
          } else {
            tempObject['latitude'] = rowValues['latitude'] || rowValues['Latitude'] || '';
            tempObject['longitude'] = rowValues['longitude'] || rowValues['Longitude'] || '';
          }
        } else if (addressKey && options.importAddresses) {
          const address = `${rowValues[addressKey]}`;
          // get lat and long from address
          await new Promise(async (resolve, reject) => {
            // Get the geocoder object
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: address }, async (results, status) => {
              if (status === 'OK') {
                // Get the lat and long from the results
                const lat = results[0].geometry.location.lat();
                const long = results[0].geometry.location.lng();
                // Set the lat and long in the tempObject
                tempObject['latitude'] = lat;
                tempObject['longitude'] = long;
                resolve();
              } else {
                this.createAlert(
                  alertTypes.error,
                  `Error: Could not find coordinates for address: ${address}. Please correct the error and try again.`
                );
              }
            });
          });
          // Still add the address to the attributes
          tempObject.attributes[key] = { '-Imported': address };
        } else {
          if (key == 'pole_tag' && !this.importingConns) {
            var poleTagSplit = value.split('::');
            var tagValue = {};
            var companyList = this.getCompanyList();
            if (poleTagSplit.length == 1) {
              tagValue.company = '';
              tagValue.tagtext = poleTagSplit[0];
              tagValue.owner = false;
            } else if (poleTagSplit.length == 2) {
              //Sanitize Capitalization
              if (companyList[poleTagSplit[0].toLowerCase()] != null) {
                poleTagSplit[0] = companyList[poleTagSplit[0].toLowerCase()];
              }
              tagValue.company = poleTagSplit[0];
              tagValue.tagtext = poleTagSplit[1];
              tagValue.owner = false;
            } else if (poleTagSplit.length == 3) {
              //Sanitize Capitalization
              if (companyList[poleTagSplit[0].toLowerCase()] != null) {
                poleTagSplit[0] = companyList[poleTagSplit[0].toLowerCase()];
              }
              tagValue.company = poleTagSplit[0];
              tagValue.tagtext = poleTagSplit[1];
              tagValue.owner = this.textToBoolean(poleTagSplit[2]);
            } else {
              tagValue.company = '';
              tagValue.tagtext = '';
              tagValue.owner = '';
            }
            value = tagValue;
          }

          // Default the attribute key to '-Imported' so it sorts before pushIds. If there is another
          // attribute already created for this node, then make the key a push id
          if (tempObject.attributes.hasOwnProperty(key) == true) {
            tempObject.attributes[key][FirebaseWorker.ref('/').push().key] = value;
          } else {
            tempObject.attributes[key] = {
              '-Imported': value
            };
          }
        }
      }
    }
    return tempObject;
  },

  textToTable: function (value, attributeModel) {
    let tableData = {};
    let attributePairs = value.split(',');
    attributePairs.forEach((pair) => {
      let attribute = (pair.split(':')[0] || '').trim();
      let tableItems = SquashNulls(attributeModel, 'table_items') || [];
      let validTableItem = tableItems.find((x) => x.attribute == attribute);
      if (validTableItem) {
        tableData[attribute] = (pair.split(':')[1] || '').trim();
      }
    });
    return tableData;
  },

  textToBoolean: function (value) {
    return value == null ||
      value == '' ||
      value == '0' ||
      value.toLowerCase() == 'false' ||
      value.toLowerCase() == 'f' ||
      value.toLowerCase() == 'no' ||
      value.toLowerCase() == 'n'
      ? false
      : true;
  },

  checkTotalItemCount: function (totalItemCount) {
    if (this.importingConns) {
      if (Number(totalItemCount) > 1000) return true;
    } else {
      if (Number(totalItemCount) > 2000) return true;
    }
    return false;
  },

  getCompanyList: function () {
    if (this.companyList == null) {
      this.companyList = {};
      var picklists = SquashNulls(this.attributesData, 'company', 'picklists');
      for (var picklistId in picklists) {
        for (var i = 0; i < picklists[picklistId].length; i++) {
          this.companyList[picklists[picklistId][i].value.toLowerCase()] = picklists[picklistId][i].value;
        }
      }
    }
    return this.companyList;
  },

  updateSkippedValues: function (skippedValues, value, location, name) {
    // Increase the skip count or start a new count
    if (skippedValues[value]) {
      skippedValues[value].count = skippedValues[value].count + 1;
    } else {
      skippedValues[value] = {
        count: 1,
        location: location,
        name: name
      };
    }
    return skippedValues;
  },

  // Round decimal places for the given coordinate
  formatCoordinate: function (coordinate) {
    if (!coordinate) return null;
    var decimalCount = 6;
    return parseFloat(coordinate).toFixed(6);
  },

  extractJobNameIfNeeded: async function (rowObject) {
    // Check if the object has a job_name property
    let formattedAttributes = Object.keys(SquashNulls(rowObject, 'attributes') || {}).map((x) => x.toLowerCase().trim().replace(/ /g, '_'));
    let jobName = '';
    if (formattedAttributes.includes('job_name')) {
      jobName = PickAnAttribute(rowObject.attributes, 'job_name');
      if (await this.jobExistsWithName(jobName)) {
        this.createAlert(
          alertTypes.warning,
          'Warning: job "' + jobName + '" exists and will not be created, but nodes will be imported into master job.'
        );
      } else {
        // Check if an array exists at this location
        if (!Array.isArray(this.subJobList[jobName])) {
          this.set('subJobList' + ('.' + jobName), []);
        }
        // Add the row object to the list of subjobs to add
        this.subJobList[jobName].push(rowObject);
      }
    } else if (formattedAttributes.includes('job_path')) {
      let jobPath = PickAnAttribute(rowObject.attributes, 'job_path').split('/');
      jobName = jobPath[jobPath.length - 1];

      if (!this.importJobsLookup[jobName]) this.importJobsLookup[jobName] = { path: PickAnAttribute(rowObject.attributes, 'job_path') };
      if (await this.jobExistsWithName(jobName)) {
        this.createAlert(
          alertTypes.warning,
          'Warning: job "' + jobName + '" exists and will not be created, but nodes will be imported into master job.'
        );
      } else if (this.importJobsLookup[jobName].path != PickAnAttribute(rowObject.attributes, 'job_path')) {
        this.importJobsLookup[jobName].duplicate = true;
      } else {
        // Check if an array exists at this location
        if (!Array.isArray(this.subJobList[jobName])) {
          this.subJobList[jobName] = [];
          //   this.set('subJobList' + ('.' + [jobName]), []);
        }
        // Add the row object to the list of subjobs to add
        this.subJobList[jobName].push(rowObject);
      }
    }
    return jobName;
  },

  getJobNameFromRowObject: function (rowObject) {
    let formattedAttributes = Object.keys(SquashNulls(rowObject, 'attributes') || {}).map((x) => x.toLowerCase().trim().replace(/ /g, '_'));
    if (formattedAttributes.includes('job_name')) return PickAnAttribute(rowObject.attributes, 'job_name');
    else if (formattedAttributes.includes('job_path')) {
      let parts = PickAnAttribute(rowObject.attributes, 'job_path').split('/');
      return parts[parts.length - 1];
    }
    return null;
  },

  createObjectFromData: async function (rowData, headers, options) {
    if (!this.attributesData)
      this.createAlert(alertTypes.error, 'Error: Model attributes could not be found.  Please verify that there is a valid job model.');
    // clear alerts from previous try
    return new Promise(async (resolve, reject) => {
      if (this.reversedAlertKeys) this.set('reversedAlertKeys', []);
      this.alerts = [];
      var newDataObject = {};
      var skippedValues = {};
      var existingPPLTags = [];
      var existingCoordinates = [];
      this.subJobsToUpdate = [];
      if (headers && this.importedHeaders?.length == 0) {
        let importedHeaders = [];
        if (this.importingConns) {
          let lat1Exists = false;
          let lng1Exists = false;
          let lat2Exists = false;
          let lng2Exists = false;
          for (var i = 0; i < headers.length; i++) {
            if (headers[i].toLowerCase().trim() == 'latitude1') {
              lat1Exists = true;
              headers[i] = 'latitude1';
            } else if (headers[i].toLowerCase().trim() == 'longitude1') {
              lng1Exists = true;
              headers[i] = 'longitude1';
            } else if (headers[i].toLowerCase().trim() == 'latitude2') {
              lat2Exists = true;
              headers[i] = 'latitude2';
            } else if (headers[i].toLowerCase().trim() == 'longitude2') {
              lng2Exists = true;
              headers[i] = 'longitude2';
            } else {
              importedHeaders.push({ name: headers[i], value: headers[i] });
            }
          }
          if (lat1Exists && lng1Exists && lat2Exists && lng2Exists) {
            importedHeaders.push({ name: 'latitude and longitude', value: 'latitude and longitude' });
          }
        } else {
          let latExists = false;
          let lngExists = false;
          for (var i = 0; i < headers.length; i++) {
            if (headers[i].toLowerCase().trim() == 'latitude') {
              latExists = true;
              headers[i] = 'latitude';
            } else if (headers[i].toLowerCase().trim() == 'longitude') {
              lngExists = true;
              headers[i] = 'longitude';
            } else {
              importedHeaders.push({ name: headers[i], value: headers[i] });
            }
          }
          if (latExists && lngExists) {
            importedHeaders.push({ name: 'latitude and longitude', value: 'latitude and longitude' });
          }
        }
        this.set('importedHeaders', importedHeaders);
      }
      // Check if all the non-blank header values are valid attributes
      var invalidHeaders = [];
      var formattedAttributes = {};
      for (var key in this.attributesData) {
        formattedAttributes[key.toLowerCase()] = key;
      }
      for (var i = 0; i < headers.length; i++) {
        if (this.correctedHeaders) {
          for (var j = 0; j < this.correctedHeaders.length; j++) {
            if (this.correctedHeaders[j].header == headers[i]) headers[i] = this.correctedHeaders[j].value;
          }
        }
        let formattedHeader = headers[i].toLowerCase().trim().replace(/ /g, '_');
        let invalidHeaderExceptions = [];
        if (this.importingConns) {
          invalidHeaderExceptions = ['', 'latitude1', 'longitude1', 'latitude2', 'longitude2', 'job_name', 'conn_id'];
        } else {
          invalidHeaderExceptions = ['', 'latitude', 'longitude', 'job_name', 'job_path', 'node_id'];
        }
        if (!invalidHeaderExceptions.includes(formattedHeader) && !formattedAttributes[formattedHeader]) {
          invalidHeaders.push({ name: headers[i] });
        }
      }

      // Check if header contains all required fields
      let lowercaseHeaders = headers.map((x) => x.toLowerCase());
      for (var i = 0; i < this.requiredFields.length; i++) {
        // add an error if any required fields do not exist
        if (lowercaseHeaders.indexOf(this.requiredFields[i]) == -1) {
          if (this.requiredFields[i] == 'address') {
            const addressIsHandled = this.importedHeaders.find((x) => x.name.toLowerCase() == 'address');
            if (addressIsHandled) continue;
          }
          if (!this.updateAttr) {
            //   invalidHeaders.push({name: this.requiredFields[i]});
            this.createAlert(alertTypes.error, 'Error: Header is missing required field "' + this.requiredFields[i] + '"');
          }
        }
      }

      // Only parse the data if the headers are valid
      if (invalidHeaders.length == 0) {
        if (this.shadowRoot.querySelector('#fixHeadersCollapse')) this.fixHeaderCollapseOpened = false;
        var chunk = 100;
        var index = 0;
        await Promise.all([
          new Promise(async (resolve) => {
            var doChunk = async () => {
              var cnt = chunk;
              while (cnt-- && index < rowData.length) {
                // process array[i] here
                var currentLine = rowData[index];
                var lineNumber = index + 2;
                // Check if the line contains all blanks
                var lineIsBlank = true;
                Object.values(currentLine).forEach((value) => {
                  if (value != '') {
                    lineIsBlank = false;
                  }
                });
                // Update the progress
                this.progressPercent = Math.ceil((index / (rowData.length - 1)) * 100);
                // Make sure the line isn't blank
                if (!lineIsBlank) {
                  var shouldSkipRow = false;
                  var rowObject = await this.createObjectFromRow(this.importedHeaders, currentLine, formattedAttributes, options);
                  var coordSkip = '';
                  var tagSkip = '';
                  if (!this.updateAttr) {
                    if (this.importingConns) {
                      // Check if latitude1 is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.latitude1) || rowObject.latitude1 == '')) ||
                        Math.abs(rowObject.latitude1) > 90
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because "latitude1" is not a valid number'
                        );
                      }
                      // Check if longitude1 is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.longitude1) || rowObject.longitude1 == '')) ||
                        Math.abs(rowObject.longitude1) > 180
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because "longitude1" is not a valid number'
                        );
                      }
                      // Check if latitude2 is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.latitude2) || rowObject.latitude2 == '')) ||
                        Math.abs(rowObject.latitude2) > 90
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because "latitude2" is not a valid number'
                        );
                      }
                      // Check if longitude2 is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.longitude2) || rowObject.longitude2 == '')) ||
                        Math.abs(rowObject.longitude2) > 180
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because "longitude2" is not a valid number'
                        );
                      }
                    } else if (options.importAddresses) {
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.latitude) || rowObject.latitude == '')) ||
                        Math.abs(rowObject.latitude) > 90
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because we could not find a valid latitude for the address'
                        );
                      }
                      // Check if longitude is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.longitude) || rowObject.longitude == '')) ||
                        Math.abs(rowObject.longitude) > 180
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because we could not find a valid longitude for the address'
                        );
                      }
                      // Check if node_type doesn't exist or doesn't have a value and warn the user
                      if (rowObject.attributes['node_type'] == null || rowObject.attributes['node_type'].value == '') {
                        this.createAlert(alertTypes.warning, 'Warning: Line #' + lineNumber + ', no node_type value is given');
                      }
                    } else {
                      // Check if latitude is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.latitude) || rowObject.latitude == '')) ||
                        Math.abs(rowObject.latitude) > 90
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because "latitude" is not a valid number'
                        );
                      }
                      // Check if longitude is valid
                      if (
                        (!shouldSkipRow && (isNaN(rowObject.longitude) || rowObject.longitude == '')) ||
                        Math.abs(rowObject.longitude) > 180
                      ) {
                        shouldSkipRow = true;
                        this.createAlert(
                          alertTypes.warning,
                          'Warning: Skipped line #' + lineNumber + ' because "longitude" is not a valid number'
                        );
                      }

                      // Check if node_type doesn't exist or doesn't have a value and warn the user
                      if (rowObject.attributes['node_type'] == null || rowObject.attributes['node_type'].value == '') {
                        this.createAlert(alertTypes.warning, 'Warning: Line #' + lineNumber + ', no node_type value is given');
                      }

                      // Check if the row has a tag_ppl attribute since it's not required
                      if ('tag_ppl' in rowObject.attributes) {
                        var tempTag = rowObject.attributes['tag_ppl'].value;
                        // Check if the ppl_tag was already in the job
                        if (!shouldSkipRow && tempTag && tempTag != '' && (await this.jobNodesHaveValue('tag_ppl', tempTag))) {
                          shouldSkipRow = true;
                          skippedValues = this.updateSkippedValues(skippedValues, tempTag, 'job', 'tag_ppl');
                        }
                        // Check if the ppl_tag already existed in the spreadsheet
                        if (!shouldSkipRow && tempTag && tempTag != '') {
                          // Check if the tag was already in the spreadsheet
                          if (existingPPLTags.indexOf(tempTag) > -1) {
                            shouldSkipRow = true;
                            skippedValues = this.updateSkippedValues(skippedValues, tempTag, 'spreadsheet', 'tag_ppl');
                          } else {
                            tagSkip = tempTag;
                          }
                        }
                      }
                    }
                  }

                  if (this.importingConns) {
                    // Check if latitude1,longitude1 and latitude2,longitude2 are close to any nodes in the job and check if both pairs already exist
                    if (!shouldSkipRow && rowObject.latitude1 && rowObject.longitude1 && rowObject.latitude2 && rowObject.longitude2) {
                      // Get the formatted coordinate pairs
                      let pair1 =
                        this.formatCoordinate(rowObject.latitude1).toString() +
                        ', ' +
                        this.formatCoordinate(rowObject.longitude1).toString();
                      let pair2 =
                        this.formatCoordinate(rowObject.latitude2).toString() +
                        ', ' +
                        this.formatCoordinate(rowObject.longitude2).toString();
                      // skip if the connection has the same beginning and end point
                      if (pair1 == pair2) {
                        this.createAlert(
                          alertTypes.warning,
                          'Warning (Line ' +
                            lineNumber +
                            '): This connection has been skipped because it has a beginning and end point at the same location.'
                        );
                        shouldSkipRow = true;
                      } else {
                        // Check if the coordinate pair matches another already in the spreadsheet
                        let duplicateConnsInSpreasheet = existingCoordinates.filter((x) => x == `${pair1}:${pair2}`).length;
                        if (duplicateConnsInSpreasheet != 0) {
                          this.createAlert(
                            alertTypes.warning,
                            'Warning (Line ' +
                              lineNumber +
                              '): This connection has been skipped because it already exists in the uploaded spreadsheet.'
                          );
                          shouldSkipRow = true;
                        } else {
                          // Make sure that each connection is not a duplicate of what already exists in the job or sub-job
                          let jobName = this.getJobNameFromRowObject(rowObject);
                          let duplicateConnsInMasterJob = 0;
                          let duplicateConnsInSubJob = 0;
                          // case for if we are importing to sub-job and master job
                          if (jobName && this.addConnsToMasterJob) {
                            duplicateConnsInMasterJob = await this.jobConnectionsHaveValue('latlong', `${pair1}:${pair2}`, {
                              returnCount: true
                            });
                            duplicateConnsInSubJob = await this.jobConnectionsHaveValue('latlong', `${pair1}:${pair2}`, {
                              jobName,
                              returnCount: true
                            });
                            if (duplicateConnsInMasterJob != 0) {
                              this.createAlert(
                                alertTypes.warning,
                                `Warning (Line ${lineNumber}): This connection has been skipped because it already exists in this job at (${pair1} : ${pair2}).`
                              );
                              shouldSkipRow = true;
                            }
                            if (duplicateConnsInSubJob != 0) {
                              this.createAlert(
                                alertTypes.warning,
                                `Warning (Line ${lineNumber}): This connection has been skipped because it already exists in the sub-job: "${jobName}" at (${pair1} : ${pair2}).`
                              );
                              shouldSkipRow = true;
                            }
                          } else if (jobName) {
                            // case for if we are importing only to the sub-job
                            duplicateConnsInSubJob = await this.jobConnectionsHaveValue('latlong', `${pair1}:${pair2}`, {
                              jobName,
                              returnCount: true
                            });
                            if (duplicateConnsInSubJob != 0) {
                              this.createAlert(
                                alertTypes.warning,
                                `Warning (Line ${lineNumber}): This connection has been skipped because it already exists in the sub-job: "${jobName}" at (${pair1} : ${pair2}).`
                              );
                              shouldSkipRow = true;
                            }
                          } else {
                            // case for if we are importing only to the master job
                            duplicateConnsInMasterJob = await this.jobConnectionsHaveValue('latlong', `${pair1}:${pair2}`, {
                              returnCount: true
                            });
                            if (duplicateConnsInMasterJob != 0) {
                              this.createAlert(
                                alertTypes.warning,
                                `Warning (Line ${lineNumber}): This connection has been skipped because it already exists in this job at (${pair1} : ${pair2}).`
                              );
                              shouldSkipRow = true;
                            }
                          }
                          if (!shouldSkipRow) {
                            // Make sure that each connection matches to nodes that exists in the job or in the specified sub-job
                            let pair1NumMatchingMasterJobCoordinates = 0;
                            let pair2NumMatchingMasterJobCoordinates = 0;
                            let pair1NumMatchingSubJobCoordinates = 0;
                            let pair2NumMatchingSubJobCoordinates = 0;
                            // case for if we are importing to sub-job and master job
                            if (jobName && this.addConnsToMasterJob) {
                              pair1NumMatchingMasterJobCoordinates = await this.jobNodesHaveValue('latlong', pair1, { returnCount: true });
                              pair2NumMatchingMasterJobCoordinates = await this.jobNodesHaveValue('latlong', pair2, { returnCount: true });
                              pair1NumMatchingSubJobCoordinates = await this.jobNodesHaveValue('latlong', pair1, {
                                jobName,
                                returnCount: true
                              });
                              pair2NumMatchingSubJobCoordinates = await this.jobNodesHaveValue('latlong', pair2, {
                                jobName,
                                returnCount: true
                              });
                              // if there is exactly one match for each of the pairs then add the node id's to the rowObject
                              if (
                                pair1NumMatchingMasterJobCoordinates == 1 &&
                                pair2NumMatchingMasterJobCoordinates == 1 &&
                                pair1NumMatchingSubJobCoordinates == 1 &&
                                pair2NumMatchingSubJobCoordinates == 1
                              ) {
                                // since we are now adding 2 different connections that could need different node id values, we will need to create a new rowObject
                                let newRowObject = JSON.parse(JSON.stringify(rowObject));
                                let nodeId1 = await this.jobNodesHaveValue('latlong', pair1, { jobName });
                                let nodeId2 = await this.jobNodesHaveValue('latlong', pair2, { jobName });
                                newRowObject['node_id_1'] = nodeId1;
                                newRowObject['node_id_2'] = nodeId2;
                                newRowObject.latitude1 = parseFloat(newRowObject.latitude1);
                                newRowObject.latitude2 = parseFloat(newRowObject.latitude2);
                                newRowObject.longitude1 = parseFloat(newRowObject.longitude1);
                                newRowObject.longitude2 = parseFloat(newRowObject.longitude2);
                                // assign this conn to its specified sub-job
                                if (!this.subJobsToUpdate[jobName]) this.subJobsToUpdate[jobName] = [];
                                this.subJobsToUpdate[jobName].push(newRowObject);

                                nodeId1 = await this.jobNodesHaveValue('latlong', pair1);
                                nodeId2 = await this.jobNodesHaveValue('latlong', pair2);
                                rowObject['node_id_1'] = nodeId1;
                                rowObject['node_id_2'] = nodeId2;
                                rowObject.latitude1 = parseFloat(rowObject.latitude1);
                                rowObject.latitude2 = parseFloat(rowObject.latitude2);
                                rowObject.longitude1 = parseFloat(rowObject.longitude1);
                                rowObject.longitude2 = parseFloat(rowObject.longitude2);
                              } else if (pair1NumMatchingMasterJobCoordinates == 1 && pair2NumMatchingMasterJobCoordinates == 1) {
                                let nodeId1 = await this.jobNodesHaveValue('latlong', pair1);
                                let nodeId2 = await this.jobNodesHaveValue('latlong', pair2);
                                rowObject['node_id_1'] = nodeId1;
                                rowObject['node_id_2'] = nodeId2;
                                rowObject.latitude1 = parseFloat(rowObject.latitude1);
                                rowObject.latitude2 = parseFloat(rowObject.latitude2);
                                rowObject.longitude1 = parseFloat(rowObject.longitude1);
                                rowObject.longitude2 = parseFloat(rowObject.longitude2);

                                if (pair1NumMatchingSubJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude1 and longitude1 does not match to any node in sub-job: "${jobName}". It will still be added to this job.`
                                  );
                                }
                                if (pair2NumMatchingSubJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude2 and longitude2 does not match to any node in sub-job: "${jobName}". It will still be added to this job.`
                                  );
                                }
                                if (pair1NumMatchingSubJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude1 and longitude1 matches to more than one node in sub-job: "${jobName}". It will still be added to this job.`
                                  );
                                }
                                if (pair2NumMatchingSubJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude2 and longitude2 matches to more than one node in sub-job: "${jobName}". It will still be added to this job.`
                                  );
                                }
                              } else if (pair1NumMatchingSubJobCoordinates == 1 && pair2NumMatchingSubJobCoordinates == 1) {
                                let nodeId1 = await this.jobNodesHaveValue('latlong', pair1, { jobName });
                                let nodeId2 = await this.jobNodesHaveValue('latlong', pair2, { jobName });
                                rowObject['node_id_1'] = nodeId1;
                                rowObject['node_id_2'] = nodeId2;
                                rowObject.latitude1 = parseFloat(rowObject.latitude1);
                                rowObject.latitude2 = parseFloat(rowObject.latitude2);
                                rowObject.longitude1 = parseFloat(rowObject.longitude1);
                                rowObject.longitude2 = parseFloat(rowObject.longitude2);
                                // assign this conn to its specified sub-job
                                if (!this.subJobsToUpdate[jobName]) this.subJobsToUpdate[jobName] = [];
                                this.subJobsToUpdate[jobName].push(rowObject);
                                // skip the row so that it doesn't get added to the master job
                                shouldSkipRow = true;

                                if (pair1NumMatchingMasterJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude1 and longitude1 does not match to any node in this job. It will still be added to the sub-job: "${jobName}".`
                                  );
                                }
                                if (pair2NumMatchingMasterJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude2 and longitude2 does not match to any node in this job. It will still be added to the sub-job: "${jobName}".`
                                  );
                                }
                                if (pair1NumMatchingMasterJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude1 and longitude1 matches to more than one node in this job. It will still be added to the sub-job: "${jobName}".`
                                  );
                                }
                                if (pair2NumMatchingMasterJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection's latitude2 and longitude2 matches to more than one node in this job. It will still be added to the sub-job: "${jobName}".`
                                  );
                                }
                              } else {
                                shouldSkipRow = true;
                                if (pair1NumMatchingMasterJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude1 and longitude1 does not match to any node in this job.`
                                  );
                                }
                                if (pair1NumMatchingSubJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude1 and longitude1 does not match to any node in sub-job: "${jobName}".`
                                  );
                                }
                                if (pair2NumMatchingMasterJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude2 and longitude2 does not match to any node in this job.`
                                  );
                                }
                                if (pair2NumMatchingSubJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude2 and longitude2 does not match to any node in sub-job: "${jobName}".`
                                  );
                                }
                                if (pair1NumMatchingMasterJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude1 and longitude1 matches to more than one node in this job`
                                  );
                                }
                                if (pair1NumMatchingSubJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude1 and longitude1 matches to more than one node in sub-job: "${jobName}".`
                                  );
                                }
                                if (pair2NumMatchingMasterJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude2 and longitude2 matches to more than one node in this job.`
                                  );
                                }
                                if (pair2NumMatchingSubJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because latitude2 and longitude2 matches to more than one node in sub-job: "${jobName}".`
                                  );
                                }
                              }
                            } else if (jobName) {
                              // case for if we are importing only to the sub-job
                              pair1NumMatchingSubJobCoordinates = await this.jobNodesHaveValue('latlong', pair1, {
                                jobName,
                                returnCount: true
                              });
                              pair2NumMatchingSubJobCoordinates = await this.jobNodesHaveValue('latlong', pair2, {
                                jobName,
                                returnCount: true
                              });
                              // if there is exactly one match for each of the pairs then add the node id's to the rowObject
                              if (pair1NumMatchingSubJobCoordinates == 1 && pair2NumMatchingSubJobCoordinates == 1) {
                                let nodeId1 = await this.jobNodesHaveValue('latlong', pair1, { jobName });
                                let nodeId2 = await this.jobNodesHaveValue('latlong', pair2, { jobName });
                                rowObject['node_id_1'] = nodeId1;
                                rowObject['node_id_2'] = nodeId2;
                                rowObject.latitude1 = parseFloat(rowObject.latitude1);
                                rowObject.latitude2 = parseFloat(rowObject.latitude2);
                                rowObject.longitude1 = parseFloat(rowObject.longitude1);
                                rowObject.longitude2 = parseFloat(rowObject.longitude2);
                                // assign this conn to its specified sub-job
                                if (!this.subJobsToUpdate[jobName]) this.subJobsToUpdate[jobName] = [];
                                this.subJobsToUpdate[jobName].push(rowObject);
                              } else {
                                if (pair1NumMatchingSubJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude1 and longitude1 do not match to any node in the specified sub-job: "${jobName}".`
                                  );
                                  shouldSkipRow = true;
                                } else if (pair2NumMatchingSubJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude2 and longitude2 do not match to any node in the specified sub-job: "${jobName}".`
                                  );
                                  shouldSkipRow = true;
                                }
                                if (pair1NumMatchingSubJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude1 and longitude1 match more than 1 node in the specified sub-job: "${jobName}".`
                                  );
                                  shouldSkipRow = true;
                                } else if (pair2NumMatchingSubJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude1 and longitude1 match more than 1 node in the specified sub-job: "${jobName}".`
                                  );
                                  shouldSkipRow = true;
                                }
                              }
                            } else {
                              // case for if we are importing only to the master job
                              pair1NumMatchingMasterJobCoordinates = await this.jobNodesHaveValue('latlong', pair1, { returnCount: true });
                              pair2NumMatchingMasterJobCoordinates = await this.jobNodesHaveValue('latlong', pair2, { returnCount: true });
                              // if there is exactly one match for each of the pairs then add the node id's to the rowObject
                              if (pair1NumMatchingMasterJobCoordinates == 1 && pair2NumMatchingMasterJobCoordinates == 1) {
                                let nodeId1 = await this.jobNodesHaveValue('latlong', pair1, { jobName });
                                let nodeId2 = await this.jobNodesHaveValue('latlong', pair2, { jobName });
                                rowObject['node_id_1'] = nodeId1;
                                rowObject['node_id_2'] = nodeId2;
                                rowObject.latitude1 = parseFloat(rowObject.latitude1);
                                rowObject.latitude2 = parseFloat(rowObject.latitude2);
                                rowObject.longitude1 = parseFloat(rowObject.longitude1);
                                rowObject.longitude2 = parseFloat(rowObject.longitude2);
                              } else {
                                if (pair1NumMatchingMasterJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude1 and longitude1 do not match to any node in this job.`
                                  );
                                  shouldSkipRow = true;
                                } else if (pair2NumMatchingMasterJobCoordinates == 0) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude2 and longitude2 do not match to any node in this job.`
                                  );
                                  shouldSkipRow = true;
                                }
                                if (pair1NumMatchingMasterJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude1 and longitude1 match more than 1 node in this job.`
                                  );
                                  shouldSkipRow = true;
                                } else if (pair2NumMatchingMasterJobCoordinates > 1) {
                                  this.createAlert(
                                    alertTypes.warning,
                                    `Warning (Line ${lineNumber}): This connection has been skipped because its latitude2 and longitude2 match more than 1 node in this job.`
                                  );
                                  shouldSkipRow = true;
                                }
                              }
                            }
                          }
                        }
                      }
                      coordSkip = `${pair1}:${pair2}`;
                    }
                  } else {
                    // Check if latitude and longitude pair are close to any value in job or spreadsheet
                    if (!shouldSkipRow && rowObject.latitude && rowObject.longitude) {
                      // Get the formatted coordinate pair
                      let pair =
                        this.formatCoordinate(rowObject.latitude).toString() + ', ' + this.formatCoordinate(rowObject.longitude).toString();
                      // Check if the coordinate pair is close to another already in the spreadsheet
                      if (existingCoordinates.indexOf(pair) > -1) {
                        if (this.updateAttr) {
                          if (this.updateAttrSelected && this.updateAttrSelected != 'latitude and longitude') {
                            this.createAlert(
                              alertTypes.warning,
                              'Warning (Line ' +
                                lineNumber +
                                '): If you add this line to the job, the latitude and longitude will be updated'
                            );
                          }
                        } else {
                          // this.createAlert(alertTypes.warning, 'Warning (Line ' + lineNumber + '): The pair (' + pair + ') is very similar to another pair in the spreadsheet: ' + existingCoordinates[existingCoordinates.indexOf(pair)]);
                        }
                      }
                      // Check if the coordinate pair is close to another already in the job
                      if (await this.jobNodesHaveValue('latlong', pair)) {
                        var keyToCheck = await this.jobNodesHaveValue('latlong', pair);
                        var existingLat = this.valueForJobNode(keyToCheck, 'latitude');
                        var existingLong = this.valueForJobNode(keyToCheck, 'longitude');
                        var existingPair = existingLat + ', ' + existingLong;
                        if (this.updateAttr) {
                          if (this.updateAttrSelected && this.updateAttrSelected != 'latitude and longitude') {
                            this.createAlert(
                              alertTypes.warning,
                              'Warning (Line ' +
                                lineNumber +
                                '): If you add this line to the job, the latitude and longitude will be updated'
                            );
                          }
                        } else {
                          this.createAlert(
                            alertTypes.warning,
                            'Warning (Line ' +
                              lineNumber +
                              '): The pair (' +
                              pair +
                              ') is very similar to another pair in the job (' +
                              existingPair +
                              ')'
                          );
                        }
                      }
                      coordSkip = pair;
                    }
                  }

                  // Check if the pole_tags look ok
                  if (!shouldSkipRow && 'pole_tag' in rowObject.attributes && !this.importingConns) {
                    for (var headerCount = 0; headerCount < headers.length; headerCount++) {
                      if (headers[headerCount] == 'pole_tag') {
                        var value = currentLine['pole_tag'] ? String(currentLine['pole_tag']).trim() : '';
                        if (value.split('::').length > 3) {
                          this.createAlert(
                            alertTypes.warning,
                            'Warning (Line ' +
                              lineNumber +
                              '): The pole_tag ' +
                              value +
                              ' does not match the format Company::Tagtext::IsOwner. (Each value should be separated by two colons.)'
                          );
                        }
                      }
                    }
                  }

                  // Add the new object if everything checks out
                  if (!shouldSkipRow) {
                    if (!this.importingConns) {
                      // Pull out the job_name info if there is any
                      await this.extractJobNameIfNeeded(rowObject);
                      if (rowObject.latitude) {
                        rowObject.latitude = parseFloat(rowObject.latitude);
                      }
                      if (rowObject.longitude) {
                        rowObject.longitude = parseFloat(rowObject.longitude);
                      }
                    }
                    // if the current row we're looking at is a duplicate
                    // if (existingCoordinates.indexOf(pair) > -1) {
                    //     // find the existing row
                    //     let existingRow = Object.keys(newDataObject).find(x => newDataObject[x].latitude == rowObject.latitude && newDataObject[x].longitude == rowObject.longitude)
                    //     // append the relevant pieces of data to the existingRow, leaving out the uncessary ones
                    //     let keys = ['lease_agreement', 'lambert_ID', 'attachment_count', 'permit_ID_(Job_number)'];
                    //     for (let j = 0; j < keys.length; j++) {
                    //         // if the attribute exists and it's different than what the existing row already has
                    //         if (SquashNulls(rowObject, 'attributes', keys[j]) && PickAnAttribute(rowObject.attributes, keys[j]) != PickAnAttribute(newDataObject[existingRow].attributes, keys[j])) {
                    //             let existingCount = Object.keys(SquashNulls(newDataObject[existingRow], 'attributes', keys[j]) || []).length;
                    //             newDataObject[existingRow].attributes[keys[j]][`-Imported${existingCount}`] = PickAnAttribute(rowObject.attributes, keys[j]);
                    //         }
                    //     }
                    // }
                    // else {
                    //     newDataObject[i] = rowObject;
                    // }
                    newDataObject[index] = rowObject;
                    // Only add the found values if we have a value and are adding the row
                    if (tagSkip != '') existingPPLTags.push(tagSkip);
                    if (coordSkip != '') existingCoordinates.push(coordSkip);
                  }
                } else {
                  this.createAlert(alertTypes.warning, 'Skipped over blank line (line #' + lineNumber + ')');
                }
                ++index;
              }
              if (index < rowData.length) {
                // set Timeout for async iteration
                setTimeout(doChunk, 1);
              } else {
                resolve();
              }
            };
            doChunk();
          })
        ]).then(() => {
          // Display warnings for all the unique tags that were skipped
          for (var value in skippedValues) {
            var plural = skippedValues[value].count > 1 ? 's' : '';
            this.createAlert(
              alertTypes.warning,
              'Skipped ' +
                skippedValues[value].count +
                ' line' +
                plural +
                ' because ' +
                skippedValues[value].name +
                ' value (' +
                value +
                ') already exists in ' +
                skippedValues[value].location
            );
          }
          resolve(newDataObject);
        });
      } else {
        if (this.attributesData) {
          let attributesList = [];
          this.createAlert(
            alertTypes.error,
            `Error: Some attributes don't exist in these models. Select which attributes you'd like to use.`
          );
          // set invalid headers and allow user to map correct attributes
          for (let attr in this.attributesData) {
            if (this.importingConns) {
              if (this.attributesData[attr].attribute_types && this.attributesData[attr].attribute_types.includes('connection')) {
                let name = this.camelCase(attr);
                attributesList.push({ attr: attr, name: name });
              }
            } else {
              if (this.attributesData[attr].attribute_types && this.attributesData[attr].attribute_types.includes('node')) {
                let name = this.camelCase(attr);
                attributesList.push({ attr: attr, name: name });
              }
            }
          }
          this.progressPercent = 0;
          this.set('attributesList', attributesList);
          this.set('invalidHeaders', invalidHeaders);
        }
        resolve(newDataObject);
      }
    });
  },

  camelCase: function (a) {
    return CamelCase(a);
  },

  getAlertStyle: function (alertKey) {
    return this.alerts[alertKey] ? 'text-align:left; color:' + this.alerts[alertKey].type.color + ';' : 'text-align: left;';
  },

  getAlertMessage: function (alertKey) {
    return this.alerts[alertKey] ? this.alerts[alertKey].message : null;
  },
  toast: function (text) {
    this.$.toast.text = text;
    this.$.toast.open();
  },
  useAddressChanged: function (e) {
    this.set('useAddress', e.target.checked);
    this.resetData();
    this.readFiles(this.files, 'nodeDropZone', { importAddresses: this.useAddress });
  }
});
